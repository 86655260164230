import { UserRole } from 'store/types/User';

enum Roles {
  Director = 'Director',
  Manager = 'Manager',
  ProgramContact = 'Program Contact',
  AccredApplicant = 'Accreditation Applicant',
  Instructor = 'Instructor',
  MasterTrainer = 'Master Trainer',
  AssessmentProctor = 'Assessment Proctor',
  ModuleTestProctor = 'Module Test Proctor',
  AssessPerfEvaluator = 'Assessment Performance Evaluator',
  CurricPerfEvaluator = 'Curriculum Performance Evaluator',
  PipelinePerfEvaluator = 'Pipeline Performance Evaluator',
  MobileCranePracExaminer = 'Mobile Crane Practical Examiner',
  RigSigPersonPracExaminer = 'Rigger/Signal Person Practical Examiner',
  Learner = 'Learner',
  Purchaser = 'Purchaser',
  AccountsPayable = 'Accounts Payable',
  Facilitator = 'Facilitator',

  // 'Certified' Roles
  CertifiedInstructor = 'Certified Instructor',
  CertifiedMasterTrainer = 'Certified Master Trainer',
  CertifiedAssessmentProctor = 'Certified Assessment Proctor',
  CertifiedModuleTestProctor = 'Certified Module Test Proctor',
  CertifiedAssessPerfEvaluator = 'Certified Assessment Performance Evaluator',
  CertifiedCurricPerfEvaluator = 'Certified Curriculum Performance Evaluator',
  CertifiedMobileCranePracExaminer = 'Certified Mobile Crane Practical Examiner',
  CertifiedRigSigPersonPracExaminer = 'Certified Rigger/Signal Person Practical Examiner',

  // CraftPro Roles
  CraftProLearner = 'CraftPro Learner',
  CraftProJobsiteEvaluator = 'CraftPro Jobsite Evaluator',
  CraftProAdmin = 'CraftPro Admin',
}
export default Roles;

export function isAccounting(roles: UserRole[] | undefined): boolean | undefined {
  return roles?.some((e) => accountingRoles.has(e.role));
}

export function isProgramAdmin(roles: UserRole[] | undefined): boolean | undefined {
  return roles?.some((e) => programAdminRoles.has(e.role));
}

const accountingRoles = new Set<string>([Roles.AccountsPayable]);

const programAdminRoles = new Set<string>([Roles.Director, Roles.Manager, Roles.ProgramContact]);
