import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { UserContext } from 'components/UserGuard';
import Modal from 'components/shared/Modal';
import * as ProfilesService from 'services/api/ProfilesService';
import AuthService from 'services/AuthService';
import { externalRoutes } from 'store/configs/Routes';
import { isAccounting } from 'store/enums/Roles';

export function HomeOverdueBalance() {
  const { roles } = useContext(UserContext);
  const overduePrograms = useOverdueBalance();
  const [open, setOpen] = useState(true);

  const handleClose = useCallback(() => setOpen(false), []);

  const billingRoute = useMemo(() => externalRoutes.billingPortal(AuthService.getIdToken()), []);

  return (
    <Modal
      title="Past Due Balance"
      open={open && Boolean(overduePrograms?.length)}
      disableBackdropClick
      actions={
        <>
          {isAccounting(roles) && (
            <Button color="secondary" variant="outlined" href={billingRoute} target="_blank" onClick={handleClose}>
              Pay My Bills and Invoices
            </Button>
          )}
          <Button color="primary" variant="contained" onClick={handleClose} sx={{ marginLeft: 'auto' }}>
            Accept
          </Button>
        </>
      }
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography>
          Your account has a past due balance. Delinquent payment can result in an account hold, which keeps programs
          from using the NCCER Systems.
        </Typography>
        <Typography>
          To view your invoice, go to the 'Pay My Bills and Invoices' tile in your account portal or click below.
          Remember, you must have the Accounts Payable role to view/pay invoices in the Billing Portal.
        </Typography>
      </Box>
    </Modal>
  );
}

function useOverdueBalance() {
  const [overduePrograms, setOverduePrograms] = useState<ProfilesService.OverdueBalanceItem[] | undefined>(undefined);

  useEffect(() => {
    async function load() {
      const res = await ProfilesService.overdueBalance();
      setOverduePrograms(res.items);
    }
    load().catch((error) => console.error(error));
  }, []);

  return overduePrograms;
}
