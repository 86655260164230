import { tenantConfig } from 'config';

const routes = {
  root: '#',

  redirect: '/redirectUrl',

  login: '/login',
  logout: '/logout',
  firstLogin: '/first-login',
  setPassword: '/set-password',

  home: '/home',

  profile: '/profile',

  settings: '/settings',

  myCredentials: '/my-credentials',
  badgeDetails: '/badge-details',
  verifyCredentials: '/verify-credentials',
  viewCredentials: '/view-credentials',
  printCredentials: '/print-credentials',

  reports: '/reports',

  onlineVerification: '/online-verification',
  ovSearch: '/ov-search',
  publicBadgeDetails: '/public-badge-details',
};

export default routes;

export const noAuthRoutes = [routes.onlineVerification, routes.ovSearch, routes.publicBadgeDetails];

export const externalRoutes = {
  billingPortal: (idToken: string) => `${tenantConfig.billingPortalUrl}?id_token=${idToken}`,
};
