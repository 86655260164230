import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { HttpService } from 'services/HttpService';

export interface NetSuiteResponse<TItem> {
  links: Link[];
  hasMore: boolean;
  count: number;
  offset: number;
  totalResults: number;
  items: TItem[];
}

export interface Link {
  rel: string;
  href: string;
}

export type OverdueBalanceItem = {
  programId: string;
  overdueBalance: number;
  daysOverdueOldest: number;
};

export async function overdueBalance(): Promise<NetSuiteResponse<OverdueBalanceItem>> {
  const url = `${API_URL}/profiles/overdueBalance`;
  const headers = {
    TenantId: tenantConfig.id,
    Authorization: AuthService.getBearerToken(),
  };

  return await HttpService.get(url, { headers }).toPromise();
}
